<template>
  <!--
    Used to provide Data in order to add a new Outro-element.
  -->
  <div class="outroAdd">
    <form>
      <div class="form-group">
        <div class="row">
          <div class="col-12">
            <h4 class="m-0 mt-3">
              {{ $t('outro') }}
            </h4>
            <hr class="m-0 mb-3">
            <label>{{ $t('name') }}</label>
            <input
              v-model="outroElement.name"
              v-focus
              :class="['form-control', { 'is-invalid': $validator.errors.has('Name') }]"
              type="text"
            >
            <span
              v-show="errors.has('Name')"
              class="badge badge-danger"
            >{{ errors.first('Name') }}</span>
          </div>
          <div class="col-12">
            <hr class="m-0 mb-3">
            <label>{{ $t('description') }}</label>
            <input
              v-model="outroElement.description"
              :class="['form-control', { 'is-invalid': $validator.errors.has('Description') }]"
              type="text"
            >
            <span
              v-show="errors.has('Description')"
              class="badge badge-danger"
            >{{ errors.first('Description') }}</span>
          </div>
          <div class="col-12">
            <hr class="m-0 mb-3">
            <label>{{ $t('file') }} (100Mb max, PNG or MP4)</label>
            <div class="input-group mb-3">
              <input
                ref="fileInput"
                type="file"
                accept="image/png,video/mp4"  
                class="form-control cursor-pointer rounded"
                @change="getFileFromInput($event)"
              >
            </div>
            <div
              v-if="uploadPercentage > 0"
              class="input-group mb-3"
            >
              <div class="input-prepend mr-3">
                <label style="width:50px;">{{ $t('staticResourceUploaderComponent.progress') }}</label>
              </div>
              <div
                class="progress"
                style="width:100%"
              >
                <div
                  :aria-valuenow="uploadPercentage"
                  :style="`width:${ uploadPercentage }%;`"
                  class="progress-bar"
                  role="progressbar"
                  aria-valuemin="0"
                  aria-valuemax="100"
                >
                  {{ uploadPercentage }} %
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="fileName && fileType == 'image/png'"
            class="col-12"
          >
            <hr class="m-0 mb-3">
            <label>{{ $t('duration') }}</label>
            <input
              v-model="outroElement.duration"
              :class="['form-control', { 'is-invalid': $validator.errors.has('Duration') }]"
              type="number"
            >
            <span
              v-show="errors.has('Duration')"
              class="badge badge-danger"
            >{{ errors.first('Duration') }}</span>
          </div>
        </div>
      </div>
      <button
        :disabled="!file || !outroElement.name || loading"
        class="btn btn-primary float-right"
        @click.prevent="addAndUploadOutro()"
      >
        <font-awesome-icon
          class="mr-1"
          icon="check"
        />
        <span>
          {{ $t('add') }}
        </span>
      </button>
      <div class="clearfix" />
    </form>
  </div>
</template>

<script>
import { errorMixin } from '@/mixins/errorMixin.js';
var videoDuration = null; // stupid hack do get video duration

export default {
  name: "OutroAdd",
  mixins: [
    errorMixin
  ],
  props: {
    introOutroId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      outroElement: {},
      loading: false,
      introOutros: null,
      duration: 0,
      file: null,
      fileName: null,
      fileType: null,
      uploadPercentage: null
    }
  },
  methods: {
    async addAndUploadOutro (){
      await this.addOutro();
      if(this.outroElement.id)
      {
        await this.uploadOutro();
      }
    },
    getFileFromInput () {
      this.file = this.$refs.fileInput.files[0];
      this.fileName = this.$refs.fileInput.files[0].name;
      this.fileType = this.$refs.fileInput.files[0].type;
      if(this.fileType == 'video/mp4')
      {
        this.getVideoDuration();
      }
      this.uploadPercentage = 0;
    },
    async addOutro () {
      let outro = {
        'Name': this.outroElement.name,
        'Description': this.outroElement.description,
        'Duration': this.outroElement.duration || videoDuration,
        'IntroOutroId': this.introOutroId,
        'SourceName': this.fileName
      }
      await this.axios.put(`/VideoPostProcessing/AddOutroScene`, outro)
        .then((response) => {
          this.outroElement.id = response.data; 
          this.error_clear();
        })
        .catch((error) => {
          this.error_clear();
          this.error_validate(error);
        });
    },
    async uploadOutro () {
      this.loading = true;
      this.uploadPercentage = 0;
      let bodyFormData = new FormData();
      bodyFormData.append('formFile', this.file);
      await this.axios.post(`/VideoPostProcessingResource/UploadOutro/${this.outroElement.id}`, 
        bodyFormData,
        { headers: { 'Content-Type': 'multipart/form-data' },
          onUploadProgress: function ( progressEvent ) {
            this.uploadPercentage = parseInt( Math.round( ( progressEvent.loaded * 100 ) / progressEvent.total ) );
          }.bind(this)
        }
      )
      .then((response) => {
        if (response && response.status && response.status == 200) {
          this.$emit("reload");
          this.$snotify.success(this.$t('staticResourceUploaderComponent.fileSavedMessage'));
        }
      })
      .finally(() => {
        this.loading = false;
        setTimeout(() => {
          this.uploadPercentage = 0;
        }, 2500);
      });
    },
    getVideoDuration () {
      var video = document.createElement('video');
      video.preload = 'metadata';
      video.onloadedmetadata = function () {
        window.URL.revokeObjectURL(video.src);
        videoDuration = Math.round(video.duration);
      }
      video.src = URL.createObjectURL(this.file);
    }
  }
}
</script>
