<template>
  <!--
    Used to display the outros of the videoPostProcessing themes.
  -->
  <Portlet 
    v-if="introOutroId" 
    :title="introOutro ? introOutro.name : $t('outroListComp.introOutro')"
    icon="film" 
    class="outroList"
  >
    <template slot="buttons">
      <button 
        class="btn btn-sm btn-primary float-right mt-2 mb-2" 
        @click="addOutro"
      >
        <font-awesome-icon
          class="mr-1"
          icon="plus"
        />
        <span>
          {{ $t('add') }}
        </span>
      </button>
      <div class="clearfix" />
    </template>

    <ul class="nav nav-tabs">
      <li class="nav-item">
        <router-link 
          :to="{ path: '/videopostprocessing/introoutro/' + introOutroId + '/intro' }" 
          class="nav-link" 
          active-class="active" 
          tag="a"
        >
          {{ $t('outroListComp.intros') }}
        </router-link>
      </li>
      <li class="nav-item">
        <router-link 
          :to="{ path: '/videopostprocessing/introoutro/' + introOutroId+'/outro' }" 
          class="nav-link" 
          active-class="active" 
          tag="a"
        >
          {{ $t('outroListComp.outros') }}
        </router-link>
      </li>
    </ul>

    <LoadingPlaceholder v-if="loading" />
    <template v-else>
      <Grid
        :ref="kgm_ref"
        :style="{height: 'auto'}"
        :data-items="kgm_computedGridItems(introOutro.outroScenes)"
        :columns="kgm_responsiveColumns()"
        :filterable="true"
        :filter="kgm_filter"
        :pageable="kgm_pagable"
        :page-size="kgm_take"
        :skip="kgm_skip"
        :take="kgm_take"
        :total="kgm_allGridItems(introOutro.outroScenes)"
        :sortable="{
          allowUnsort: kgm_allowUnsort,
          mode: kgm_sortMode
        }"
        :sort="kgm_sort"
        selected-field="selected"
        @rowclick="kgm_gridOnRowClick"
        @filterchange="kgm_gridFilterChange"
        @pagechange="kgm_gridPageChange"
        @sortchange="kgm_gridSortChange"
      >
        <template
          slot="optionsTemplate"
          slot-scope="{props}"
        >
          <td :class="props.className">
            <div class="btn-group">
              <button
                class="btn btn-primary btn-sm"
                @click="editOutro(props.dataItem.id)"
              >
                <font-awesome-icon
                  class="mr-2"
                  icon="edit"
                />
                <span>{{ $t('edit') }}</span>
              </button>
              <button
                class="btn btn-primary btn-sm"
                @click="getOutro(props.dataItem)"
              >
                <font-awesome-icon
                  class="mr-2"
                  icon="eye"
                />
                <span>Show</span>
              </button>
              <button
                class="btn btn-secondary btn-sm"
                @click="openConfirmModal(props.dataItem.id)"
              >
                <font-awesome-icon
                  class="mr-2 gray"
                  icon="trash"
                />
                <span>{{ $t('remove') }}</span>
              </button>
            </div>
          </td>
        </template>
        <div
          slot="nameFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <input
            ref="nameFilterInput"
            :value="props.value"
            type="text"
            class="form-control mr-2 rounded-right"
            placeholder="please enter a filter"
            @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetInput('nameFilterInput', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
        <div
          slot="durationFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <input
            ref="durationFilterInput"
            :value="props.value"
            type="text"
            class="form-control mr-2 rounded-right"
            placeholder="please enter a filter"
            @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetInput('durationFilterInput', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
      </Grid>
      <Sidebar
        v-if="showSidebar"
        :show-sidebar="showSidebar"
        @close="close"
      >
        <OutroAdd
          v-if="addingElement"
          :intro-outro-id="introOutroId"
          @reload="getIntroOutroAndCloseSidebar"
        />
        <IntroOutroElementEdit
          v-else
          :intro-outro-id="introOutroId"
          :element-id="currentId"
          :element-name="elementName"
          @reload="getIntroOutroAndCloseSidebar"
        />
      </Sidebar>
    </template>
    
    <SweetModal
      ref="confirmDelete"
      :title="$t('outroListComp.deleteElement')"
      icon="warning"
      blocking
      class="overflowHidden"
    >
      <p>{{ $t('outroListComp.sureToDelete') }}</p>
      <button
        slot="button"
        class="btn btn-secondary float-left mb-3"
        @click="$refs.confirmDelete.close()"
      >
        <font-awesome-icon
          class="mr-2 gray"
          icon="times"
        />
        <span>{{ $t('cancel') }}</span>
      </button>
      <button
        slot="button"
        class="btn btn-danger float-right mb-3"
        @click="removeOutro()"
      >
        <font-awesome-icon
          class="mr-2"
          icon="trash"
        />
        <span>{{ $t('delete') }}</span>
      </button>
      <div class="clearfix" />
    </SweetModal>
    <SweetModal
      ref="videoPlayerModal"
      title="<span>Preview</span>"
      @close="closeModal()"
    >
      <video-player
        v-if="playerOptions.sources[0] != null"
        id="videoPlayerOne"
        ref="videoRef"
        :playsinline="true"
        :options="playerOptions"
      />
    </SweetModal>
    <SweetModal
      ref="imageViewerModal"
      title="<span>Preview</span>"
      @close="closeModal()"
    >
      <img
        v-if="imageUrl"
        loading="lazy"
        :src="imageUrl"
        class="img-fluid"
      >
    </SweetModal>
    <router-link
      :to="`/videopostprocessing/introoutro`"
      class="btn btn-secondary mt-3"
    >
      <font-awesome-icon
        class="mr-1"
        icon="caret-left"
      />
      <span>
        {{ $t('back') }}
      </span>
    </router-link>
  </Portlet>
</template>

<script>
import Sidebar from '@/components/Base/Sidebar.vue';
import OutroAdd from '@/components/VideoPostprocessing/IntroOutro/OutroAdd.vue';
import IntroOutroElementEdit from '@/components/VideoPostprocessing/IntroOutro/IntroOutroElementEdit.vue';

import { SweetModal } from 'sweet-modal-vue';
import { kendoGridMixin } from '@/mixins/kendoGridMixin.js';
import 'video.js/dist/video-js.css';
import { videoPlayer } from 'vue-video-player';

export default {
  name: "OutroList",
  components: {
    Sidebar,
    OutroAdd,
    IntroOutroElementEdit,
    SweetModal,
    videoPlayer
  },
  mixins: [
    kendoGridMixin
  ],
  props: {
    introOutroId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      loading: true,
      introOutro: null,
      currentId: null,
      showSidebar: false,
      addingElement: false,
      elementName: null,
      deleteIndex: null,
      imageUrl: null,
      playerOptions: {
        autoplay: true,
        responsive: true,
        muted: false,
        preload: true,
        language: 'en',
        playbackRates: [0.25, 0.5, 1, 1.5, 2],
        sources: [null],
        poster: null,
        fluid: true
      },
      kgm_filter: {
        logic: "and",
        filters: [
          {
            field: 'name',
            operator: 'contains',
            value: ''
          }
        ]
      },
      kgm_sort: [
        {
          "field": "name",
          "dir": "asc"
        }
      ],
      kgm_columns: [
        {
          field: 'name',
          filterable: true,
          filter: 'text',
          title: 'Name',
          filterCell: "nameFilter"
        },
        {
          field: 'description',
          filterable: true,
          filter: 'text',
          title: 'Description',
          hideOn: ['xlDown'],
          filterCell: "nameFilter"
        },
        {
          field: 'mediaType',
          filterable: true,
          filter: 'text',
          title: 'Type',
          width: '120px',
          hideOn: ['xsDown'],
          filterCell: "nameFilter"
        },
        {
          field: 'duration',
          filterable: true,
          filter: 'numeric',
          title: 'Duration',
          width: '120px',
          hideOn: ['smDown'],
          filterCell: "durationFilter"
        },
        {
          filterable: false,
          title: 'Options',
          cell: 'optionsTemplate',
          width: '270px'
        }
      ]
    }
  },
  created () {
    this.getIntroOutroWithLoading();
    
    if (this.$route.fullPath.includes("/intro")) {
      this.elementName = "introScenes";
    }
    if (this.$route.fullPath.includes("/outro")) {
      this.elementName = "outroScenes";
    }
  },
  methods: {
    //#region  Sidebar
    show () {
      this.showSidebar = true;
    },
    close () {
      this.showSidebar = false;
    },
    editOutro (outroId) {
      this.currentId = outroId;
      this.addingElement = false;
      this.show();
    },
    addOutro () {
      this.addingElement = true;
      this.show();
    },
    closeModal () {
      this.playerOptions.sources = [null];
      this.playerOptions.poster = null;
    },
    //#endregion

    //#region API-calls
    getIntroOutroAndCloseSidebar () {
      this.getIntroOutro();
      this.$emit("reloadAuditLogs");
      this.close();
    },
    getIntroOutroWithLoading () {
      this.loading = true;
      this.getIntroOutro();
    },
    getIntroOutro () {
      this.axios.get('/VideoPostProcessing/GetIntroOutro?introOutroId=' + this.introOutroId)
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.data == null) {
            return;
          }
          this.introOutro = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    addOutroScene () {
      this.axios.put('/VideoPostProcessing/AddOutroScene?introOutroId=' + this.introOutroId)
        .then(() => {
          this.getIntroOutro();
        })
        .catch(() => {
          this.loading = false;
        });
    },
    openConfirmModal (index) {      
      this.deleteIndex = index;
      this.$refs.confirmDelete.open();
    },
    removeOutro () {
      this.axios.delete('/VideoPostProcessing/DeleteOutroScene?outroSceneId=' + this.deleteIndex)
        .then(() => {
          this.getIntroOutro();
          this.$emit("reloadAuditLogs");
        })
        .catch(() => {
          this.loading = false;
        })
        .finally(() => {
          this.deleteIndex = null;
          this.$refs.confirmDelete.close();
        });
    },
    getOutro (outro) {
      this.axios.get(`/VideoPostProcessingResource/GetOutro/${ outro.id }`)
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.data == null) {
            return;
          }
          if(outro.mediaType == "Video")
          {
            this.playerOptions.poster = response.data.url;
            this.playerOptions.sources = [{
              type: "video/mp4",
              src: response.data.url
            }]; 
            this.$refs.videoPlayerModal.open();
                        window.setTimeout(() => {
              if (this.$refs && this.$refs.videoRef && this.$refs.videoRef.player) {
                this.$refs.videoRef.player.playbackRate(1);
                this.$refs.videoRef.player.volume(0.2);
                this.$refs.videoRef.player.height(100);
              }
            }, 200);  
          }
          else if(outro.mediaType == "Image")
          {
            this.imageUrl = response.data.url;
            this.$refs.imageViewerModal.open();
          }
        })
        .finally(() => {
          this.loading = false;
        });
    }
    //#endregion
  }
}
</script>
<style>
.video-js {
  width: 100%;
}
.outroList .sweet-modal {
  max-width: 100%;
  width: 50%;
}
@media only screen and (max-width: 600px) {
  .outroList .sweet-modal {
    max-width: 100%;
    width: 100%;
  }
}
.vjs-big-play-button {
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
}
video {
  max-width: 50vw !important;
}
.video-player video {
  max-width: 100% !important;
  width: 100% !important;
  background-color: #cccccc !important;
}
</style>